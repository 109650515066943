import ButtonComponent from '../../button/ButtonComponent.vue'
import QrComponent from '../../qr/QrComponent.vue'
import TextComponent from '../../text/TextComponent.vue'
import HtmlComponent from '../../html/HtmlComponent.vue'
import CancelSubscription from '../../cancel-subscription/CancelSubscription.vue'
import OpenInBrowser from '../../open-in-browser/OpenInBrowser.vue'
import ImageComponent from '../../image/ImageComponent.vue'
import SocialMediaComponent from '../../social-media/SocialMediaComponent.vue'
import ProductComponent from '../../product/ProductComponent.vue'
import VideoComponent from '../../video/VideoComponent.vue'
import GifComponent from '../../gif/GifComponent.vue'
import SeparatorComponent from '../../separator/SeparatorComponent.vue'
import EventBus from '@/util/EventBus'
import { TypeComponent } from '../../../../../enums/TypeComponent.js'

export default {
  props: {
    landing: { type: Object },
    containerIndex: { type: Number },
    slotIndex: { type: Number },
    cancelSlots: { type: Number },
  },
  name: 'DrawComponent',
  components: {
    CancelSubscription,
    OpenInBrowser,
    ButtonComponent,
    QrComponent,
    TextComponent,
    HtmlComponent,
    ImageComponent,
    SocialMediaComponent,
    ProductComponent,
    VideoComponent,
    GifComponent,
    SeparatorComponent,
  },
  data: function () {
    return {
      removableComponents: ['cancel-subscription'],
      component: null,
      TypeComponent: TypeComponent,
      user: this.$store.getters['auth/getUser'],
    }
  },
  created: function () {
    this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
  },
  computed: {
    isRemovable () {
      if (!this.user) {
        this.user = JSON.parse(localStorage.getItem('user'))
      }
      return !this.removableComponents.includes(this.component.type) || this.cancelSlots > 1 || this.user.avoidUnsubscribeBlock
    },
  },
  methods: {
    removeComponent () {
      if (this.landing.editorHelper.componentSelected.containerIndex === this.containerIndex && this.landing.editorHelper.componentSelected.slotIndex === this.slotIndex) {
        this.landing.editorHelper.componentSelected.type = null
        this.landing.editorHelper.componentSelected.containerIndex = -1
        this.landing.editorHelper.componentSelected.slotIndex = -1
        this.landing.editorHelper.componentSelected.key = ''
      }

      this.landing.structure.containers[this.containerIndex].slots[this.slotIndex].splice(0, 1)
    },

    viewComponentProperties () {
      EventBus.$emit('showPropertiesTabEvent', this.component.type, this.containerIndex, this.slotIndex, this.component.id)
    },
  },
}
