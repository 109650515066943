import SubscriptionPlanService from '../../services/subscription-plan.service'

export class Subscriptions {
  static WHATSAPP = 'whatsapp'
  static CHATBOT = 'chatbot'

  static checkSubscription (plan, callbackOK, callbackKO) {
    const active = JSON.parse(localStorage.getItem('user'))?.subscriptions[plan]
    Subscriptions.getSubscriptionStatus(active, plan, callbackOK, callbackKO)
    this.activeCallback(active, callbackOK, callbackKO)
  }

  static async getSubscriptionStatus (localActive, plan, callbackOK, callbackKO) {
    const response = await SubscriptionPlanService.isSubscribed({ plan })
    if (localActive !== response.active) {
      this.updateUser(plan, response.active)
      this.activeCallback(response.active, callbackOK, callbackKO)
    }
  }

  static activeCallback (active, callbackOK, callbackKO) {
    if (active) {
      if (callbackOK) {
        callbackOK()
      }
    } else {
      if (callbackKO) {
        callbackKO()
      }
    }
  }

  static updateUser (key, active) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (!user.subscriptions) {
      user.subscriptions = []
    }
    user.subscriptions[key] = active
    localStorage.setItem('user', JSON.stringify(user))
  }
}
