import moment from 'moment'
import VueTagsInput from '@johmun/vue-tags-input'
import EventBus from '@/util/EventBus'
import State from '@/util/State'
import { isObjEmpty } from '@/util/ValidateObject.js'
import CampaignTemplateHistory from '@/classes/Campaigns/CampaignTemplateHistory.js'
import CampaignTemplateHistorySelected from '@/classes/Campaigns/CampaignTemplateHistorySelected.js'
import Campaign from '@/models/Campaign'
import { ChannelType } from '@/models/ChannelType'
import { SendingStatuses } from '@/models/SendingStatuses'
import { CampaignType } from '@/models/CampaignType'
import ComponentStatusStore from '@/models/ComponentStatusStore'
import { ChannelMailing } from '@/models/ChannelMailing'
import { SaveBarProperties } from '@/models/SaveBarProperties'
import { MailingPreview as MailingPreviewClass } from '@/classes/preview/MailingPreview'
import { ContactsFileUploadResponse } from '@/classes/ContactsFileUploadResponse'
import { MailingComponentsData } from '@/classes/componentsData/MailingComponentsData'
import { Landing } from '@/components/Template/LandingEditor/classes/Landing'
import CampaignService from '@/services/campaign.service'
import groupService from '@/services/group.service'
import LandingTemplateService from '@/services/landing-template.service'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue'
import ImportFile from '@/components/ContactSource/ImportFile/ImportFile.vue'
import ImportManual from '@/components/ContactSource/ImportManual/ImportManual.vue'
import ImportGroups from '@/components/ContactSource/ImportGroups/ImportGroups.vue'
import ChangeSourceDialog from '@/components/ContactSource/ChangeSourceDialog/ChangeSourceDialog.vue'
import SendLater from '@/components/SendLater/SendLater.vue'
import ShippingMode from '@/components/ShippingMode/ShippingMode.vue'
import ContactSource from '@/components/ContactSource/ContactSource.vue'
import AiAssistantMailing from '@/components/AiAssistant/AiAssistantMailing.vue'
import MeetOptions from '@/components/MeetOptions/MeetOptions.vue'
import BirthdayOptions from '@/components/BirthdayOptions/BirthdayOptions.vue'
import TestModal from '@/components/Modals/TestModal/TestModal.vue'
import BalanceAlert from '@/components/Modals/BalanceAlert/BalanceAlert.vue'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import LandingEditor from '@/components/Template/LandingEditor/LandingEditor.vue'
import LandingPreview from '@/components/Template/LandingEditor/common/landing-preview/LandingPreview.vue'
import CampaingHeaderTitle from '../CampaingHeader/CampaingHeaderTitle/CampaingHeaderTitle.vue'
import CampaignPreview from '../CampaignPreview/CampaignPreview.vue'
import AdvancedOptions from './AdvancedOptions/AdvancedOptions.vue'
import GoogleAnalytics from './GoogleAnalytics/GoogleAnalytics.vue'
import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import MessagePreview from './MessagePreview/MessagePreview.vue'
import ModalValidateSend from '../ModalValidateSend/ModalValidateSend.vue'
import ModalValidateInfo from '../ModalValidateInfo/ModalValidateInfo.vue'
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import CampaignModalExit from '../CampaignModalExit/CampaignModalExit.vue'
import CountryService from '@/services/country.service'
import AiTextAssistantMailing from '@/components/AiAssistant/AiTextAssistantMailing.vue'
import _ from 'lodash'

export default {
  name: 'CampaignMailing',
  components: {
    HeaderTopDashboard,
    SendLater,
    VueTagsInput,
    ShippingMode,
    ContactSource,
    MeetOptions,
    AdvancedOptions,
    BirthdayOptions,
    TestModal,
    BalanceAlert,
    CampaignPreview,
    ButtonMultimedia,
    OverlayManager,
    LandingEditor,
    LandingPreview,
    CampaingHeaderTitle,
    GoogleAnalytics,
    MultiSelect,
    ImportFile,
    ImportManual,
    ImportGroups,
    ChangeSourceDialog,
    EmojiPickerInput,
    MessagePreview,
    ModalValidateSend,
    ModalValidateInfo,
    VideoPlayer,
    CampaignModalExit,
    AiAssistantMailing,
    AiTextAssistantMailing,
    quill: null,
  },
  data: function () {
    return {
      saveBarProperties: new SaveBarProperties(),
      drawer: null,
      panel: [],
      componentsData: new MailingComponentsData(),
      campaign: new Campaign(new ChannelMailing()),
      preview: new MailingPreviewClass(),
      channelType: ChannelType.MAILING_ID,
      urls: {
        redirectUrl: '/',
        preview: 'campaign/mailing/preview',
        store: 'mailing/store',
        send: 'mailing/send',
        update: 'mailing/update',
        paymentUrl: '/payments',
        submitUrl: '',
      },
      fields: {
        availableFields: {},
        currentFieldsMessage: [],
        currentUrlFieldsMessage: [],
      },
      options: new ComponentStatusStore(false, false, 'now'),
      landing: { id: -1, name: '', preview: '' },
      errorsAlert: {
        show: false,
        textContent: '',
        type: 'error',
      },
      test: { phone: '', phonePrefix: '', testContacts: [] },
      emptyRecipients: false,
      contactsSourceErrors: {
        show: false,
        textContent: '',
        type: 'error',
      },

      landingTemplate: new Landing(null),
      contactsFileUploadResult: new ContactsFileUploadResponse(),
      sendTestDialog: false,
      readyStatus: {
        ready: false,
      },
      showEditor: false,
      multiSelectHeaders: {
        name: { header: this.$t('Grupo') },
        contacts: { header: this.$t('Contactos'), align: 'center' },
      },
      nextGroupsPage: 1,
      lastGroupsPage: 10,
      groupsTotal: 0,
      selectedGroups: [],
      sourceKeys: {
        IMPORT_KEY: 'import',
        CONTACTS_KEY: 'contacts',
        IMPORT_COPY_PASTE_KEY: 'import-copy-paste',
      },
      changeRecipientsSource: false,
      nextRecipientsSource: null,
      timer: null,
      showErrors: false,
      uniqueCampaignName: true,
      activeEmojis: true,
      campaignPreview: false,
      campaignTemplateHistory: new CampaignTemplateHistory(),
      campaignTemplateHistorySelected: new CampaignTemplateHistorySelected(),
      updateLanding: true,
      backgroundColorInput: null,
      reRenderLanding: 0,
      state: new State('state', ['backgroundColorInput', 'urlCampaignExit']),
      showValidateModal: false,
      loadingWhiteList: false,
      campaignMailingSendingStatusValidate: 16,
      campaignMailingSendingStatusImport: 14,
      showCampaignExit: false,
      urlCampaignExit: '',
      confirmCampaignExit: false,
      routeLeave: true,
      isSendingTest: false,
      isSendingCampaign: false,
      campaignClone: {},
      campaignDataClone: {},
      campaignSave: false,
      showValidateModalInfo: false,
      readyClone: false,
      aiDrawerCtrl: {
        show: false,
      },
      user: this.$store.getters['auth/getUser'],
      groupsLoaded: false,
      tempLandingEditorContent: '',
    }
  },
  computed: {
    isValidSendTest: function () {
      const templateHasStructure = this.landing !== -1 && this.landing?.structure?.containers?.length
      const localTemplateHasStructure = this.landingTemplate.id === -1 && this.landingTemplate?.structure?.containers?.length
      const hasTemplateStructure = templateHasStructure || localTemplateHasStructure
      return this.saveBarProperties.invalid !== true && hasTemplateStructure
    },
    isValidSave: function () {
      return !this.tooManyShippingHours &&
              this.saveBarProperties.invalid !== true
              // && this.campaignSave
    },
    isValidSend: function () {
      const templateHasStructure = this.landing !== -1 && this.landing?.structure?.containers?.length
      const localTemplateHasStructure = this.landingTemplate.id === -1 && this.landingTemplate?.structure?.containers?.length
      const hasTemplateStructure = templateHasStructure || localTemplateHasStructure
      return this.preview.totalRecipients !== 0 &&
             !this.tooManyShippingHours &&
             this.saveBarProperties.invalid !== true &&
             hasTemplateStructure &&
             this.hasUnsubscribeSection
    },
    hasUnsubscribeSection () {
      return this.landingTemplate.structure.containers.reduce((prev, curr) => {
        return !!curr.slots[0].find(s => s.type === 'cancel-subscription') || !!prev
      }, false) || this.user.avoidUnsubscribeBlock
    },
    getShippingTotalHours () {
      const minutes = this.campaign.sendings[0].batchParts * this.campaign.sendings[0].batchMinutes
      return Math.floor(minutes / 60).toFixed(0)
    },
    tooManyShippingHours () {
      return this.getShippingTotalHours >= 48
    },

    // eslint-disable-next-line complexity
    isValidWhiteList: function () {
      if (
        (this.campaign.sendings[0].statusId === SendingStatuses.PENDING) ||
        (this.campaign.sendings[0].statusId === SendingStatuses.PREPARING) ||
        (this.campaign.sendings[0].statusId === SendingStatuses.IMPORTING) ||
        (this.campaign.sendings[0].statusId === SendingStatuses.PREPARING_IMPORT) ||
        (this.campaign.sendings[0].statusId === SendingStatuses.VALIDATED)
      ) {
        this.validateModalInfo()
        return true
      }

      if (this.componentsData.awsWhiteListed === null) {
        return false
      }

      if (!isObjEmpty(this.componentsData.awsWhiteListed) && this.componentsData.awsWhiteListed.template_validation_needed === 0) {
        return true
      }

      return false
    },
    timezonesList: function () {
      const list = []
      for (const timezone in this.componentsData.timezones) {
        list.push({ value: timezone, text: this.componentsData.timezones[timezone] })
      }
      return list
    },
  },
  watch: {
    landingTemplate: {
      handler () {
        this.changeLandingTemplate()
        this.campaign.sendings[0].channel.landingType = this.landingTemplate.type
        this.campaign.sendings[0].channel.htmlContent = this.landingTemplate.htmlContent
      },
      deep: true,
    },
    campaign: {
      handler () {
        this.changeCampaignValidate()
        this.campaignSave = !_.isEqual(this.campaign, this.campaignClone)
      },
      deep: true,
    },
  },
  created () {
    if (!this.user) {
      this.user = JSON.parse(localStorage.getItem('user'))
    }
    EventBus.$emit('showLoading', true)
    const campaignId = this.$route.params.id
    this.campaign.sendings[0].channel_type_id = ChannelType.MAILING_ID
    if (campaignId) {
      CampaignService.getMailingEditCampaignData(campaignId, this.$router.currentRoute.query.type)
        .then(
          (response) => {
            this.readyTemplateData = false
            this.componentsData = response.componentsData
            this.campaign.load(response.campaign)
            // this.loadAllGroups()
            this.loadCampaignGroups()
            this.setEditUrl()
            this.preview = new MailingPreviewClass(response.preview)
            this.contactsFileUploadResult = new ContactsFileUploadResponse(response.contactsFileUploadResult)
            this.campaign.costs.sendingCost = this.preview.sendingCost
            this.fields.availableFields = response.availableFields
            if (response.landing.error === 1) {
              EventBus.$emit('showAlert', 'danger', this.$t('La plantilla utilizada para crear esta campaña se ha borrado.'))
            }
            this.landing = response.landing
            this.landing.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(this.landing.preview)
            this.landingTemplate.structure = this.campaign.sendings[0].channel.templateStructure
            this.landingTemplate.customVars = this.fields.availableFields

            this.landingTemplate.type = this.campaign.sendings[0].channel.landingType
            this.landingTemplate.htmlContent = this.campaign.sendings[0].channel.htmlContent

            this.landingTemplate.setEditorHelper()
            this.showPanels()
            this.setType()
            this.setReady()
            EventBus.$emit('showLoading', false)
            this.setClone()
          },
          (error) => {
            if (error.response.data.message === 'no_edit_campaign') {
              const message = this.$t('No puedes editar esta campaña')
              this.showErrorAlert(message)
              const that = this
              EventBus.$emit('showLoading', false)
              setTimeout(function () {
                that.$router.go(-1)
              }, 1000)
            }
          },
        )
    } else {
      CampaignService.getMailingCreateData(this.$router.currentRoute.query.type)
        .then(
          (response) => {
            this.componentsData = new MailingComponentsData(response.componentsData)
            this.campaign.subtype = response.campaign.subtype
            this.loadDefaults()
            this.landingInitComplete()
            EventBus.$emit('showLoading', false)
            this.setClone()
          },
        )
    }

    this.backgroundColorInput = document.getElementsByClassName('backgroundColorInput')
  },
  mounted () {
    EventBus.$on('multimediaSelected', (url, type) => {
      if (type === 'landings') {
        this.selectLanding(url)
      }
    })

    EventBus.$on('changedLanguage', () => {
      CountryService.getUserCountries().then(
        (response) => {
          this.componentsData.countries = response.countries
        },
      )
    })

    // setTimeout(() => {
    //   this.campaignClone = _.cloneDeep(this.campaign)
    //   this.campaignSave = false
    //   this.readyClone = true
    // }, 2500)
  },
  beforeDestroy () {
    EventBus.$off('multimediaSelected')
  },
  beforeRouteUpdate (to, from, next) {
    if (to.name === 'Login') {
      next()
    } else if (this.routeLeave === false) {
      next()
    } else if (this.confirmCampaignExit) {
      next()
    } else {
      this.openCampaignExit(to)
      next(false)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name === 'Login') {
      next()
    } else if (this.routeLeave === false) {
      next()
    } else if (this.confirmCampaignExit) {
      next()
    } else {
      this.openCampaignExit(to)
      next(false)
    }
  },
  methods: {
    setClone () {
      this.campaignDataClone = {
        fromEmail: this.campaign.sendings[0].channel.fromEmail,
        fromName: this.campaign.sendings[0].channel.fromName,
        replyTo: this.campaign.sendings[0].channel.replyTo,
        subject: this.campaign.sendings[0].channel.subject,
        templateStructure: _.cloneDeep(this.campaign.sendings[0].channel.templateStructure),
        sendingTimezone: this.campaign.sendings[0].sendingTimezone,
        landingType: this.campaign.sendings[0].channel.landingType,
      }
      // console.log('setClone', this.campaignDataClone)
      this.campaignClone = _.cloneDeep(this.campaign)
      this.campaignSave = false
      this.readyClone = true
    },
    updateClone () {
      this.campaignDataClone = {
        fromEmail: this.campaign.sendings[0].channel.fromEmail,
        fromName: this.campaign.sendings[0].channel.fromName,
        replyTo: this.campaign.sendings[0].channel.replyTo,
        subject: this.campaign.sendings[0].channel.subject,
        templateStructure: _.cloneDeep(this.campaign.sendings[0].channel.templateStructure),
        sendingTimezone: this.campaign.sendings[0].sendingTimezone,
        landingType: this.campaign.sendings[0].channel.landingType,
      }
    },
    setSubject (subject) {
      this.campaign.sendings[0].channel.subject = subject
    },
    openAiAssistant () {
      this.aiDrawerCtrl.show = true
    },
    openVideoPlayer () {
      let videoSms = null
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic) {
        videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 9)
      }
      if (this.campaign.subtype === CampaignType.automatic.subtypes.meet) {
        videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 10)
      }
      if (this.campaign.subtype === CampaignType.automatic.subtypes.birthday) {
        videoSms = this.$store.state.videoPlayer.items[this.$i18n.locale].find(video => video.id === 11)
      }

      this.$store.dispatch('videoPlayer/toggle', {
        show: true,
        url: videoSms.url,
        title: videoSms.title,
      })
    },
    saveState () {
      this.state.setState(this.$data)
      EventBus.$emit('contactSource:saveState')
    },
    recoverState () {
      this.state.getState(this.$data)
      EventBus.$emit('contactSource:recoverState')
    },
    saveAndExit () {
    },
    setInvalidElements (invalid) {
      this.saveBarProperties.invalid = invalid
    },
    setDefaultCampaignData () {
      const date = moment().format('YYYY-MM-DD-HH:mm:ss')
      this.campaign.name = 'MAIL' + '-' + date
      this.campaign.sendings[0].sendingTimezone = this.componentsData.defaultTimezone
      this.setType()
    },
    setType () {
      this.campaign.type = this.campaign.subtype === CampaignType.automatic.subtypes.meet || this.campaign.subtype === CampaignType.automatic.subtypes.birthday
        ? CampaignType.automatic.id
        : CampaignType.basic.id
    },
    updatePreview (data) {
      this.preview = new MailingPreviewClass(data)
      this.landingTemplate.customVars = this.fields.availableFields
      EventBus.$emit('hideAlert')
    },
    refreshPreviewCosts () {
      if (this.preview.totalRecipients === 0) {
        return
      }
      const contactSource = this.$refs.contactSource
      if (this.campaign.recipientsSource === this.sourceKeys.IMPORT_KEY || this.campaign.recipientsSource === this.sourceKeys.IMPORT_COPY_PASTE_KEY) {
        contactSource.reparseFile()
      } else {
        contactSource.loadPreviewData()
      }
    },

    updateContactsFileUploadResult (data) {
      this.contactsFileUploadResult = new ContactsFileUploadResponse(data)
      EventBus.$emit('hideAlert')
    },
    save (redirect = false, sendTest = false) {
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED
      if (!sendTest) {
        this.campaign.sendings[0].recipientsTest = []
      }
      return this.persist(this.urls.submitUrl, redirect)
    },
    saveAndBalance () {
      this.routeLeave = false
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED

      const data = this.campaign.formatData()
      if (!this.hasLanding()) {
        this.showErrorAlert(this.$t('Debes seleccionar una landing page'))
        return false
      }
      CampaignService.persist(data, this.urls.submitUrl).then(
        (response) => {
          this.campaign.id = response.sending.campaign_id
          this.campaign.sendings[0].channel.id = response.sending.channel_id
          this.setEditUrl()
          EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado su campaña correctamente'))
          this.$router.push({
            name: 'addPayment',
            query: {
              campaign_id: this.campaign.id,
              campaign_type: 'mailing',
              campaign_sub_type: this.campaign.subtype,
            },
          })
        },
        (error) => {
          if (error.response.data.balanceAlert) {
            return this.showBalanceAlert(error.response.data.isScheduled)
          }
          this.showErrorAlert(this.$t(error.response.data.internal))
        },
      )
    },
    send () {
      this.routeLeave = false
      const contactSource = this.$refs.contactSource
      if (contactSource.validate()) {
        this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
        if (this.campaign.sendings[0].sendingDate) {
          this.campaign.sendings[0].statusId = SendingStatuses.PENDING
        } else {
          this.campaign.sendings[0].statusId = SendingStatuses.PREPARING
        }
        if (this.urls.submitUrl === this.urls.store) {
          this.urls.submitUrl = this.urls.send
        }
        this.isSendingCampaign = true
        return this.persist(this.urls.submitUrl, true)
      }
    },
    whiteList () {
      this.toggleShowValidateModal()
    },
    toggleShowValidateModal () {
      this.showValidateModal = !this.showValidateModal
    },
    validateSending () {
      this.routeLeave = false
      this.loadingWhiteList = true
      this.campaign.sendings[0].totalRecipients = this.preview.totalRecipients
      this.campaign.sendings[0].statusId = SendingStatuses.SAVED

      const data = this.campaign.formatData()
      if (!this.hasLanding()) {
        this.showErrorAlert(this.$t('Debes seleccionar una landing page'))
        return false
      }
      CampaignService.persist(data, this.urls.submitUrl).then(
        (response) => {
          this.campaign.id = response.sending.campaign_id
          this.campaign.sendings[0].channel.id = response.sending.channel_id
          this.setEditUrl()

          const data = {
            sendingId: response.sending.id,
            campaginId: response.sending.campaign_id,
            type: this.campaign.subtype,
          }
          CampaignService.mailingSendingChangeStatusValidating(data).then(
            () => {
              EventBus.$emit('showAlert', 'success', this.$t('Campaña actualizada correctamente'))
              this.$router.push({ name: 'HomePage' })
            },
            () => {
              EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al validar la campaña'))
            },
          ).finally(() => {
            this.loadingWhiteList = false
            this.toggleShowValidateModal()
          })
        },
        () => {
          EventBus.$emit('showAlert', 'danger', this.$t('Ocurrió un error al validar la campaña'))
        },
      )
    },
    toggleValidateModalInfo () {
      this.showValidateModalInfo = !this.showValidateModalInfo
    },
    validateModalInfo () {
      if (this.campaign.sendings[0].statusId === SendingStatuses.VALIDATED) {
        this.showValidateModalInfo = true
      } else {
        this.showValidateModalInfo = false
      }
    },
    persist (url, redirect = false) {
      const data = this.campaign.formatData()
      if (!this.hasLanding()) {
        this.showErrorAlert(this.$t('Debes seleccionar una landing page'))
        return false
      }
      CampaignService.persist(data, url)
        .then(
          (response) => {
            if (response.error) {
              EventBus.$emit('showAlert', 'danger', this.$t(response.error_message))
              this.confirmCampaignExit = true
              this.$router.push({ path: this.urls.redirectUrl })
              EventBus.$emit('flashCampaign', this.campaign)
            } else {
              if (redirect) {
                this.$router.push({ path: this.urls.redirectUrl })
              } else {
                this.campaign.id = response.sending.campaign_id
                this.campaign.sendings[0].channel.id = response.sending.channel_id
                this.setEditUrl()
              }
              let message = this.isSendingTest
                ? this.$t('Se ha enviado la prueba correctamente')
                : this.$t('Se ha guardado su campaña correctamente')
              message = this.isSendingCampaign
                ? this.$t('Se ha creado su campaña correctamente')
                : message
              EventBus.$emit('showAlert', 'success', message)

              this.isSendingTest = false
              this.isSendingCampaign = false

              this.campaignClone = _.cloneDeep(this.campaign)
              this.updateClone()
              this.campaignSave = false
            }
          },
          (error) => {
            if (error.response.data.balanceAlert) {
              return this.showBalanceAlert(error.response.data.isScheduled)
            }
            this.showErrorAlert(this.$t(error.response.data.internal))
          },
        )
    },
    showErrorAlert (errors) {
      EventBus.$emit('showAlert', 'danger', errors)
    },
    showBalanceAlert (isScheduled) {
      if (isScheduled) {
        this.$bvModal.show('balanceAlertScheduled')
      } else {
        this.$bvModal.show('balanceAlert')
      }
    },
    saveAndRedirectToPayment () {
      this.urls.redirectUrl = this.urls.paymentUrl
      this.campaign.costs.skipValidateBalance = true
      return this.save()
    },
    confirmScheduled () {
      this.campaign.costs.skipValidateBalance = true
      return this.send()
    },
    async updateLandingPreview (history = true) {
      LandingTemplateService.getPreviewFromStructure({
        structure: this.landingTemplate.structure,
        typeLanding: 'Email',
      }).then(
        (response) => {
          this.landing.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(response.preview)
          this.landing.structure = this.landingTemplate.structure
          this.campaign.sendings[0].channel.templateId = this.landing.id
          this.campaign.sendings[0].channel.templateStructure = this.landingTemplate.structure

          if (history) {
            this.updateCampaignTemplateHistory(
              JSON.parse(JSON.stringify(this.landing.id)),
              JSON.parse(JSON.stringify(this.landingTemplate.structure)),
            )
          }
        },
      )
    },
    updateCampaignTemplateHistory (landingId, structure) {
      if (this.campaignTemplateHistory.steps[0].templateId === this.campaignTemplateHistory.templateDefault) {
        this.campaignTemplateHistory.steps = [
          {
            templateId: landingId,
            structure: structure,
          },
        ]
        return
      }

      if (JSON.stringify(this.landingTemplate.structure) === JSON.stringify(this.campaignTemplateHistory.steps[this.campaignTemplateHistory.steps.length - 1].structure)) {
        return
      }

      if (this.campaignTemplateHistory.steps.length === this.campaignTemplateHistory.limitSteps) {
        this.campaignTemplateHistory.steps = [
          {
            templateId: landingId,
            structure: structure,
          },
        ]
      } else {
        this.campaignTemplateHistory.steps.push({
          templateId: landingId,
          structure: structure,
        })
      }

      this.updateCampaignTemplateHistorySelected(
        this.campaignTemplateHistory.steps.length - 1,
        landingId,
        structure,
      )
    },
    async previousCampaignTemplateStructure () {
      this.updateLanding = false
      const position = this.campaignTemplateHistorySelected.steps - 1
      this.landingTemplate.templateId = JSON.parse(JSON.stringify(this.campaignTemplateHistory.steps[position].templateId))
      this.landingTemplate.structure = JSON.parse(JSON.stringify(this.campaignTemplateHistory.steps[position].structure))

      this.reRenderLanding += 1

      this.updateCampaignTemplateHistorySelected(
        position,
        this.landingTemplate.templateId,
        this.landingTemplate.structure,
      )

      await this.updateLandingPreview(false)
      this.$nextTick(() => {
        document.querySelectorAll("div[id^='cancel-subscription-component-']")?.forEach(element => element?.click())
      })
      this.updateLanding = true
    },
    async nextCampaignTemplateStructure () {
      this.updateLanding = false
      const position = this.campaignTemplateHistorySelected.steps + 1
      this.landingTemplate.templateId = JSON.parse(JSON.stringify(this.campaignTemplateHistory.steps[position].templateId))
      this.landingTemplate.structure = JSON.parse(JSON.stringify(this.campaignTemplateHistory.steps[position].structure))

      this.reRenderLanding += 1

      this.updateCampaignTemplateHistorySelected(
        position,
        this.landingTemplate.templateId,
        this.landingTemplate.structure,
      )

      await this.updateLandingPreview(false)
      this.$nextTick(() => {
        document.querySelectorAll("div[id^='cancel-subscription-component-']")?.forEach(element => element?.click())
      })
      this.updateLanding = true
    },
    updateCampaignTemplateHistorySelected (steps, ladingId, structure) {
      if (steps > this.campaignTemplateHistory.initSteps) {
        this.campaignTemplateHistorySelected.disablePrevious = false
      }

      if (steps === this.campaignTemplateHistory.initSteps) {
        this.campaignTemplateHistorySelected.disablePrevious = true
      }

      if (steps < (this.campaignTemplateHistory.steps.length - 1)) {
        this.campaignTemplateHistorySelected.disableNext = false
      }

      if (steps === (this.campaignTemplateHistory.steps.length - 1)) {
        this.campaignTemplateHistorySelected.disableNext = true
      }

      this.campaignTemplateHistorySelected.steps = steps
      this.campaignTemplateHistorySelected.templateId = ladingId
      this.campaignTemplateHistorySelected.structure = structure
    },
    selectLanding (url) {
      const path = url.substring(url.indexOf('=') + 1)
      const data = { path: path }
      this.tempLandingEditorContent = this.$refs.landingEditorComponent.$el.innerHTML
      this.fullReinitTemplate()
      LandingTemplateService.getLandingByPath(data).then(
        (response) => {
          this.landing.id = response.id
          this.landing.name = response.name
          this.landingTemplate.name = response.name
          this.landingTemplate.id = response.id
          this.landing.preview = 'data:text/html;charset=utf-8,' + encodeURIComponent(response.preview)
          this.landing.structure = JSON.parse(response.structure)
          this.landing.htmlContent = response.htmlContent

          this.landingTemplate.structure = JSON.parse(response.structure)
          this.landingTemplate.customVars = this.fields.availableFields
          this.landingTemplate.setEditorHelper()

          this.campaign.sendings[0].channel.templateId = this.landing.id
          this.campaign.sendings[0].channel.templateStructure = this.landingTemplate.structure

          this.landingTemplate.htmlContent = response.htmlContent
          this.landingTemplate.type = response.type
          this.tempLandingEditorContent = false
          this.$refs.landingEditorComponent.updateContent(this.landingTemplate.htmlContent)
          EventBus.$emit('updateSelectedCompany')
        },
      )
    },
    getPreview () {
      this.campaignPreview = true
      this.$nextTick(() => {
        const landingPreview = this.$refs.landingPreview
        landingPreview.showPreviewFromStructure(this.landingTemplate.structure)
      })
    },
    setReady () {
      this.readyStatus.ready = false
      setTimeout(() => {
       this.readyStatus.ready = true
      })
    },
    saveAndSendTest () {
      this.isSendingTest = true
      EventBus.$emit('close-test-dialogs')
      this.campaign.sendings[0].recipientsTest = [this.componentsData.testEmailAddress]
      return this.save(false, true)
    },
    setEditUrl () {
      this.urls.submitUrl = this.urls.update + '/' + this.campaign.id
    },
    showPanels () {
      if (this.campaign.sendings[0].sendingDate || this.campaign.sendings[0].expirationDate) {
        this.panel = [0]
      }
      if (
        this.campaign.sendings[0].tags.length > 0 ||
        this.campaign.sendings[0].batchMinutes ||
        this.campaign.sendings[0].channel.certified ||
        this.campaign.sendings[0].channel.flash
      ) {
        if (this.panel !== null) {
          this.panel = [0, 1]
        } else {
          this.panel = [1]
        }
      }
    },
    cancelSendLater () {
      this.panel.splice(0, 1)
    },
    hasLanding () {
      return this.campaign.sendings[0].channel.templateStructure
    },
    async loadGroups () {
      if (this.nextGroupsPage > this.lastGroupsPage) {
        return
      }
      if (this.nextGroupsPage === 1) {
        this.componentsData.groups = []
      }
      let groups = []
      if (this.campaign.subtype === CampaignType.basic.subtypes.basic) {
        groups = await groupService.getWithContactsSendingsByChannel({
          channelType: this.channelType,
          page: this.nextGroupsPage,
        })
      } else {
        groups = await groupService.getWithContactsSendingsByChannelWithDates({
          channelType: this.channelType,
          page: this.nextGroupsPage,
        })
      }
      this.lastGroupsPage = groups.last_page
      this.groupsTotal = groups.total
      this.componentsData.groups = [...this.componentsData.groups, ...groups.data]
      this.nextGroupsPage++
      this.componentsData.groups = this.componentsData.groups.filter(ar => !this.selectedGroups.find(rm => rm.id === ar.id))
      this.groupsLoaded = true
    },
    setSelectedGroups () {
      this.selectedGroups = [...this.componentsData.groups].filter(group => this.campaign.campaignGroups.find(campaignGroup => campaignGroup.group_id === group.id))
    },
    async loadCampaignGroups () {
      this.componentsData.groups = []
      const groups = await groupService.getGroupsById({
        channelType: this.channelType,
        groupIds: this.campaign.campaignGroups.map(g => g.group_id),
      })
      this.componentsData.groups = [...groups]
      this.setSelectedGroups()
      this.loadGroups()
    },
    async loadAllGroups () {
      this.componentsData.groups = []
      const groups = await groupService.getAllGroups({
        channelType: this.channelType,
      })
      this.componentsData.groups = [...groups]
      this.setSelectedGroups()
      this.loadGroups()
    },
    onCleanSelectedGroups () {
      this.selectedGroups = []
      this.componentsData.groups = [...this.componentsData.groups]
    },
    onCheckShowDrawer (recipientsSource) {
      this.saveState()
      if (this.campaign.recipientsSource !== recipientsSource) {
        if (this.preview.totalRecipients > 0) {
          this.nextRecipientsSource = recipientsSource
          this.changeRecipientsSource = true
        } else {
          this.openDrawer(recipientsSource)
        }
      } else {
        this.openDrawer(recipientsSource)
      }
    },
    openDrawer (recipientsSource) {
      this.campaign.recipientsSource = recipientsSource
      EventBus.$emit('contactSource:showDrawer', recipientsSource)
    },
    confirmOpenDrawer () {
      EventBus.$emit('contactSource:cleanDrawer')
      EventBus.$emit('contactSource:resetDrawer')
      this.onCleanSelectedGroups()
      this.preview = new MailingPreviewClass()
      this.changeRecipientsSource = false
      this.saveState()
      this.openDrawer(this.nextRecipientsSource)
    },
    cancelOpenDrawer () {
    },
    closePreview () {
      this.campaignPreview = false
    },
    closeAcceptDrawer () {
      this.saveState()
      EventBus.$emit('contactSource:saveState')
    },
    closeCancelDrawer () {
      this.recoverState()
      if (this.preview.totalRecipients === 0) {
        EventBus.$emit('contactSource:resetDrawer')
        this.onCleanSelectedGroups()
      } else {
        EventBus.$emit('contactSource:recoverState')
      }
    },
    getErrorsWithUniqueError (errors) {
      return this.uniqueCampaignName ? errors : [...errors, ...[this.$t('El nombre de campaña ya existe').toString()]]
    },
    validateCampaignName () {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        CampaignService.checkCampaignName({ name: this.campaign.name }).then((data) => {
          this.uniqueCampaignName = data.error !== 1
        })
      }, 700)
    },
    showDrawer (recipientsSource) {
      this.$emit('checkShowDrawer', recipientsSource)
    },
    isSelectedRecipientsSource (recipientsSource) {
      return this.campaign.recipientsSource === recipientsSource
    },
    highlight (recipientsSource) {
      return (this.isSelectedRecipientsSource(recipientsSource) && this.preview.totalRecipients > 0)
              ? 'font-weight-bold'
              : ''
    },
    setEmojiToSubject (emoji) {
      const input = this.$refs.subjectInput.$refs.input
      const sentence = input.value
      const len = sentence.length
      let pos = input.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.campaign.sendings[0].channel.subject = before + emoji + after

      this.$nextTick().then(() => {
        input.selectionStart = input.value.length
        input.click()
      })
    },
    setEmojiToPreHeader (emoji) {
      const input = this.$refs.preHeader.$refs.input
      const sentence = input.value
      const len = sentence.length
      let pos = input.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.campaign.sendings[0].channel.preHeader = before + emoji + after

      this.$nextTick().then(() => {
        input.selectionStart = input.value.length
        input.click()
      })
    },
    setEmojiToFromName (emoji) {
      const input = this.$refs.fromName.$refs.input
      const sentence = input.value
      const len = sentence.length
      let pos = input.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.campaign.sendings[0].channel.fromName = before + emoji + after

      this.$nextTick().then(() => {
        input.selectionStart = input.value.length
        input.click()
      })
    },
    addFieldToSubject (item) {
      const input = this.$refs.subjectInput.$refs.input
      const sentence = input.value
      const len = sentence.length
      let pos = input.selectionStart
      if (pos === undefined) {
        pos = 0
      }

      const before = sentence.substr(0, pos)
      const after = sentence.substr(pos, len)

      this.campaign.sendings[0].channel.subject = before + '{' + item + '}' + after

      this.$nextTick().then(() => {
        input.selectionStart = pos + item.length + 2
        input.selectionEnd = pos + item.length + 2
        input.click()
      })
    },
    setColSubject () {
      if (this.activeEmojis) {
        return 'col-md-9'
      } else {
        return 'col-md-10'
      }
    },
    showOverlayPreview () {
      EventBus.$emit('showOverlayPreview')
    },
    showMultimedia (currentRootDirectory) {
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
    checkAndSetEventDate () {
      const programCampaignDate = localStorage.getItem('programCampaignDate')
      if (programCampaignDate && this.campaign.subtype === 'basic') {
        this.panel = [0]
        this.campaign.sendings[0].sendingDate = programCampaignDate
      }
      localStorage.removeItem('programCampaignDate')
    },
    reinitTemplate () {
      const landingType = parseInt(this.landingTemplate.type)
      if (landingType === 1) {
        this.landingTemplate.htmlContent = ''
        this.$refs.landingEditorComponent.updatedHtmlCode(this.landingTemplate.htmlContent)
      } else {
        this.landingTemplate = new Landing(null)
        this.landingTemplate.type = landingType
        this.landing = { id: -1, name: '', preview: '' }
        this.campaign.sendings[0].channel.templateId = -1
        this.campaign.sendings[0].channel.templateStructure = ''
        EventBus.$emit('select-template-editor-tab', 'blocks-tab')
      }
    },

    fullReinitTemplate () {
      this.landingTemplate = new Landing(null)
      this.landingTemplate.htmlContent = ''
      this.$refs.landingEditorComponent.updatedHtmlCode(this.landingTemplate.htmlContent)
      this.landing = { id: -1, name: '', preview: '' }
      this.campaign.sendings[0].channel.templateId = -1
      this.campaign.sendings[0].channel.templateStructure = ''
      EventBus.$emit('select-template-editor-tab', 'blocks-tab')
    },

    changeLandingTemplateBackgroundColor () {
      this.updateLandingPreview()
    },
    // eslint-disable-next-line complexity
    changeLandingTemplate () {
      const evento = event
      if (this.updateLanding && this.backgroundColorInput.length === 0) {
        this.updateLandingPreview()
        return
      }

      if (this.backgroundColorInput.length > 0 && evento === undefined) {
        const that = this
        if (this.backgroundColorInput.length > 0) {
          // eslint-disable-next-line max-depth
          for (let i = 0; i < this.backgroundColorInput.length; i++) {
            this.backgroundColorInput[i].addEventListener('change', that.changeLandingTemplateBackgroundColor)
          }
        }
        return
      }

      if (this.updateLanding && this.backgroundColorInput.length > 0 && !isObjEmpty(evento)) {
        this.updateLandingPreview()
      }
    },
    updateSelectedGroups (newGroups) {
      this.selectedGroups = newGroups
      this.campaign.groups = newGroups.map(g => g.id)
      if (this.campaign.recipientsSource === this.sourceKeys.CONTACTS_KEY) {
        if (newGroups.length) {
          this.preview.totalRecipients = newGroups.reduce((prev, curr) => {
            return prev + curr.contacts
          }, 0)
        } else {
          this.preview.totalRecipients = 0
        }
      }
    },
    loadDefaults () {
      this.loadGroups()
      this.showSendLater = true
      this.setDefaultCampaignData()
      this.urls.submitUrl = this.urls.store
      this.checkAndSetEventDate()
      this.setReady()
    },
    closeCampaignExit () {
      this.showCampaignExit = false
      this.campaignExit = false
    },
    saveCampaignExit () {
      this.showCampaignExit = false
      this.confirmCampaignExit = true
      this.save()
      this.$router.push(this.urlCampaignExit.fullPath)
    },
    noSaveCampaignExit () {
      this.showCampaignExit = false
      this.confirmCampaignExit = true
      this.$router.push(this.urlCampaignExit.fullPath)
    },
    openCampaignExit (to) {
      if (this.isValidSave === true) {
        this.showCampaignExit = true
        this.urlCampaignExit = to
      } else {
        this.confirmCampaignExit = true
        this.$router.push(to.fullPath)
      }
    },
    landingInitComplete () {
      this.campaignTemplateHistory.reset()
      this.campaignTemplateHistory.addTemplate({
        templateId: 0,
        structure: this.landingTemplate.structure,
      })
      this.campaignTemplateHistorySelected.reset(this.landingTemplate.structure)
    },
    changeCampaignValidate () {
      const campaignData = {
        fromEmail: this.campaign.sendings[0].channel.fromEmail,
        fromName: this.campaign.sendings[0].channel.fromName,
        replyTo: this.campaign.sendings[0].channel.replyTo,
        subject: this.campaign.sendings[0].channel.subject,
        templateStructure: _.cloneDeep(this.campaign.sendings[0].channel.templateStructure),
        sendingTimezone: this.campaign.sendings[0].sendingTimezone,
        landingType: this.campaign.sendings[0].channel.landingType,
      }

      // console.log('changeCampaignValidate', this.readyClone, this.campaign, this.campaignClone, campaignData, this.campaignDataClone, _.isEqual(campaignData, this.campaignDataClone))

      // if (
      //   this.campaign.sendings[0].statusId === SendingStatuses.VALIDATED &&
      //   _.isEqual(this.campaign, this.campaignClone) === false &&
      //   this.readyClone === true) {
      //     this.save()
      // }
      if (
        this.campaign.sendings[0].statusId === SendingStatuses.VALIDATED &&
        _.isEqual(campaignData, this.campaignDataClone) === false &&
        this.readyClone === true) {
          this.save()
      }
    },
  },
}
