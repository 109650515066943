export default class User {
  id = null
  vid = null
  username = null
  email = null
  isoCountry = null
  isCountryEu = true
  isoTld = null
  lang = null
  token = null
  tokenExpire = null
  tokenType = null
  rememberToken = null
  canGoToOldDashboard = null
  currencyCode = null
  currencySymbol = null
  showRating = null
  showAiProfile = null
  showBillingProfile = null
  enable = null
  subscriptions = null
  subscriptions = null
  avoidUnsubscribeBlock = false
  avoidUnsubscribeLink = false
  emailFooterEditable = false
  referrer = null
  maxSyncs = null
  userTimezone = null

  constructor (user) {
    if (!user) return
    this.id = user.id
    this.vid = user.vid
    this.username = user.username
    this.email = user.email
    this.isoCountry = user.isoCountry
    this.isCountryEu = user.isCountryEu || true
    this.isoTld = user.isoTld
    this.lang = user.lang
    this.token = user.token
    this.tokenExpire = user.tokenExpire
    this.tokenType = user.tokenType
    this.rememberToken = user.rememberToken
    this.canGoToOldDashboard = user.canGoToOldDashboard
    this.currencyCode = user.currencyCode
    this.currencySymbol = user.currencySymbol
    this.showRating = user.showRating
    this.showAiProfile = user.showAiProfile
    this.showBillingProfile = user.showBillingProfile
    this.enable = user.enable
    this.subscriptions = user.subscriptions
    this.subscriptions = user.subscriptions
    this.subscriptions = user.subscriptions
    this.avoidUnsubscribeBlock = user.avoidUnsubscribeBlock
    this.avoidUnsubscribeLink = user.avoidUnsubscribeLink
    this.emailFooterEditable = user.emailFooterEditable
    this.referrer = user.referrer
    this.maxSyncs = user.maxSyncs
    this.userTimezone = user.userTimezone
  }
}
