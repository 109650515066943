import EventBus from '@/util/EventBus.js'
import getEnv from '@/util/env'
import { v4 as uuidv4 } from 'uuid'
import EmojiPickerInput from '@/components/EmojiPickerInput/EmojiPickerInput.vue'
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue'
import { EditorView } from '@codemirror/view'
import { basicSetup } from 'codemirror'
import { html } from '@codemirror/lang-html'
import { EditorState } from '@codemirror/state'
import { defaultKeymap } from '@codemirror/commands'

import Quill from '@/lib/quill.js'
import '@/lib/quill.snow.css'
import prettier from 'prettier'
import parserHtml from 'prettier/parser-html'

const Parchment = Quill.import('parchment')
const Delta = Quill.import('delta')

const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)
const BackgroundStyle = Quill.import('attributors/style/background')
Quill.register(BackgroundStyle, true)
const ColorStyle = Quill.import('attributors/style/color')
Quill.register(ColorStyle, true)
const DirectionStyle = Quill.import('attributors/style/direction')
Quill.register(DirectionStyle, true)
const FontStyle = Quill.import('attributors/style/font')
Quill.register(FontStyle, true)
const SizeStyle = Quill.import('attributors/style/size')
Quill.register(SizeStyle, true)

var FontAttributor = Quill.import('formats/font')
var fonts = [
  'arial',
  'verdana',
  'tahoma',
  'times new roman',
  'georgia',
  'courier',
]
FontAttributor.whitelist = fonts
Quill.register(FontAttributor, true)

var Size = Quill.import('attributors/style/size')
Size.whitelist = ['6px', '7px', '8px', '9px', '10px', '11px', '12px', '13px', '14px', '15px', '16px', '18px', '20px', '22px', '24px', '36px', '40px', '48px', '54px', '64px', '96px', '128px']
Quill.register(Size, true)

const config = {
  scope: Parchment.Scope.INLINE,
  whitelist: ['1.0em', '1.1em', '1.2em', '1.3em', '1.4em', '1.5em', '1.6em', '1.8em', '2em', '2.5em', '3em'],
}

var lineHtStyle = new Parchment.StyleAttributor('lineheight', 'line-height', config)
Quill.register(lineHtStyle)

const configFonts = {
  scope: Parchment.Scope.INLINE,
  whitelist: ['Arial, Helvetica, sans-serif', 'Helvetica, Arial, sans-serif', 'Verdana, sans-serif', 'TimesNewRoman,Times New Roman, Georgia, serif, sans-serif', 'tahoma, verdana, sans-serif', 'Georgia, TimesNewRoman,Times New Roman, serif, sans-serif'],
}

var fontFaceStyle = new Parchment.StyleAttributor('custom-font-family', 'font-family', configFonts)
Quill.register(fontFaceStyle)

export default {
  name: 'TextEditor',
  components: { EmojiPickerInput, ColorPicker },
  props: {
    component: {
      type: Object,
      default: { },
    },
    showFormButton: {
      type: Boolean,
      default: false,
    },
    showAttachmentsButton: {
      type: Boolean,
      default: false,
    },
    showNiaButton: {
      type: Boolean,
      default: false,
    },
    showCustomFields: {
      type: Boolean,
      default: false,
    },
    checkUnsubscribeLink: {
      type: Boolean,
      default: false,
    },
    nonEditable: {
      type: Boolean,
      default: false,
    },
    justHtmlEditor: {
      type: Boolean,
      default: false,
    },
    landing: {},
  },
  data: function () {
    return {
      eventUuid: '',
      quill: null,
      showHtmlDialog: false,
      htmlContent: '',
      addingMultimedia: false,
      fontSizes: ['6px', '7px', '8px', '9px', '10px', '11px', '12px', '13px', '14px', '15px', '16px', '18px', '20px', '22px', '24px', '36px', '40px', '48px', '54px', '64px', '96px', '128px'],
      colors: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#b7b7b7', '#b74949', '#d99e2b', '#dfd949', '#a7b94a', '#4d74c9', '#7949b7', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'],
      pickerColor: '',
      currentColor: '',
      pickerBackgroundColor: '',
      currentBackgroundColor: '',
      range: { index: 0, length: 0 },
      lineHeightOptions: ['1.0', '1.1', '1.2', '1.3', '1.4', '1.5', '1.6', '1.8', '2', '2.5', '3'],
      usedColors: { color: [], background: [] },
      selectedFont: '',
      fonts: [
        { text: 'Sans-Serif', value: 'sans-serif' },
        { text: 'Arial', value: 'Arial, Helvetica, sans-serif' },
        { text: 'Georgia', value: 'Georgia, TimesNewRoman,Times New Roman, serif, sans-serif' },
        { text: 'Helvetica', value: 'Helvetica, Arial, sans-serif' },
        { text: 'Times New Roman', value: 'TimesNewRoman,Times New Roman, Georgia, serif, sans-serif' },
        { text: 'Tahoma', value: 'tahoma, verdana, sans-serif' },
        { text: 'Verdana', value: 'Verdana, sans-serif' },
      ],
      clickedInput: false,
      toolBarEditorId: '',
      editorId: '',
      view: null,
      user: this.$store.getters['auth/getUser'],
    }
  },
  created: function () {
    if (!localStorage.getItem('usedColors')) {
      localStorage.setItem('usedColors', JSON.stringify(this.usedColors))
    } else {
      const usedColors = JSON.parse(localStorage.getItem('usedColors'))
      this.usedColors.color = usedColors.color
      this.usedColors.background = usedColors.background
    }
  },
  mounted: function () {
    const uuid = uuidv4()
    this.toolBarEditorId = 'quill-toolbar-editor-id-' + uuid
    this.editorId = 'quill-editor-id-' + uuid
    this.eventUuid = 'ai-mailing-text-' + uuid

    EventBus.$on('multimediaSelected', this.setComponentData)
    EventBus.$on('hook:beforeDestroy', () => {
      EventBus.$off('multimediaSelected', this.setComponentData)
    })
    EventBus.$on('AiTextAssistantMailingSendText', this.setAiText)

    setTimeout(() => {
      this.initEditor()
    })
  },
  computed: {
    customVars () {
      const items = []
      for (const property in this.landing.customVars) {
        items.push({ text: this.landing.customVars[property], value: '{' + property + '}' })
      }
      return items
    },
    quillHistory () {
      return this.quill ? { undo: this.quill.history.stack.undo.length, redo: this.quill.history.stack.redo.length } : { undo: 0, redo: 0 }
    },
  },
  methods: {
    setOl () {
      this.quill.format('list', 'ordered')
    },
    setUl () {
      this.quill.format('list', 'bullet')
    },

    formatFont () {
      this.quill.format('custom-font-family', this.selectedFont)
    },
    saveColor (type, color) {
      if (!this.usedColors[type].includes(color)) {
        this.usedColors[type].push(color)
        localStorage.setItem('usedColors', JSON.stringify(this.usedColors))
      }
    },
    addLineHeight (lineHeightValue) {
      this.quill.format('lineheight', lineHeightValue + 'em')
    },
    formatBackgroudColor (color) {
      this.quill.format('background', color)
      this.saveColor('background', color)
      this.currentBackgroundColor = color
    },

    formatColor (color, save = false) {
      this.quill.format('color', color)
      if (save) {
        this.saveColor('color', color)
      }
      this.currentColor = color
    },
    addTextToMessage (text) {
      const startIndex = this.range.index
      this.quill.insertText(startIndex, text)
      this.component.tinymce = this.quill.root.innerHTML
    },

    setAndShowHtmlDialog () {
      const parser = new DOMParser()
      const htmlDoc = parser.parseFromString(this.quill.getSemanticHTML(), 'text/html')

      const emptyParagraphs = htmlDoc.querySelectorAll('p:empty')
      emptyParagraphs.forEach(p => {
        const brElement = document.createElement('br')
        p.replaceWith(brElement)
      })

      this.htmlContent = htmlDoc.body.innerHTML
      this.showHtmlDialog = true

      // setTimeout(() => {
      //   const setup = [...basicSetup]
      //   // eslint-disable-next-line no-unused-vars
      //   this.view = new EditorView({
      //     extensions: [
      //       setup,
      //       html(),
      //       EditorView.theme({
      //         '&': { 'max-height': '425px', 'max-width': '100%', overflow: 'auto' },
      //         '.cm-scroller': { overflow: 'auto' },
      //         '.cm-content, .cm-gutter': { minHeight: '200px' },
      //       }),
      //     ],
      //     parent: document.getElementById('code-editor-custom'),
      //   })
      //   this.view.dispatch({ changes: { from: 0, insert: htmlDoc.body.innerHTML } })

      //   const doc = this.view.state.doc.toString()
      //   const formatted = prettier.format(doc, {
      //     parser: 'html',
      //     plugins: [parserHtml],
      //     tabWidth: 2,
      //     useTabs: false,
      //   })
      //   this.view.dispatch({
      //     changes: { from: 0, to: this.view.state.doc.length, insert: formatted },
      //   })

      //   this.htmlContent = formatted
      // })
    },
    setHtmlEdition () {
      this.quill.root.innerHTML = this.htmlContent
      this.component.tinymce = this.quill.root.innerHTML
      this.showHtmlDialog = false
    },
    formAction () {
      this.addingMultimedia = true
      this.showMultimedia('formularios')
    },
    attachmentAction () {
      this.addingMultimedia = true
      this.showMultimedia('adjuntos')
    },

    async setAiText (params) {
      if (params.eventUuid !== this.eventUuid) return

      const content = `<p style=" font-size: ${this.component.fontSizeContent}px;color: #3F4254;font-family: Helvetica,Arial,sans-serif;"> ${params.idea}</p>`


      const clipboard = this.quill.getModule('clipboard')
      const pastedDelta = clipboard.convert({ html: content })
      const delta = new Delta()
                        .retain(this.range.index)
                        .delete(this.range.length)
                        .concat(pastedDelta)
      this.quill.updateContents(delta, Quill.sources.USER)
      this.component.tinymce = this.quill.root.innerHTML
    },
    openAiTextMailing () {
      EventBus.$emit('AiTextAssistantMailingOpen', this.eventUuid)
    },
    setComponentData (url, type, fileId) {
      if (this.addingMultimedia) {
        // console.log('type file:', type) // No quitar log por ahora hasta que se aplique vuex
        if (type === 'attachment') {
          const link = getEnv('VUE_APP_ASSETS_URL') + url
          this.insertUrl(link, this.$t('Ir al adjunto'))
        }

        if (type === 'form') {
          const user = JSON.parse(localStorage.getItem('user'))
          this.component.urlPreview[fileId] = getEnv('VUE_APP_API_URL') + 'api/forms/preview/' + fileId + '?token=' + user.token
          this.component.url[fileId] = '[FORM_' + fileId + ']'
          this.insertUrl(this.component.url[fileId], this.$t('Ir al formulario'), fileId)
        }
        this.addingMultimedia = false
      }
    },
    initEditor () {
      this.quill = new Quill('#' + this.editorId, {
        // debug: 'info',
        modules: {
          toolbar: {
            container: '#' + this.toolBarEditorId,
          },
          history: {
            delay: 2000,
            maxStack: 500,
            userOnly: true,
          },
        },
        placeholder: this.$t('Escribe un texto') + '...',
        theme: 'snow',
      })

      const content = this.component.tinymce

      // const clipboard = this.quill.getModule('clipboard')
      // const pastedDelta = clipboard.convert({ html: content })
      // const delta = new Delta().concat(pastedDelta)
      // this.quill.setContents(delta, Quill.sources.USER)

      this.quill.root.innerHTML = content

      setTimeout(() => {
        this.quill.history.clear()
      })

      this.quill.on('selection-change', (range) => {
        if (range) {
          this.range = range
        }
      })


      this.quill.on('text-change', (delta, oldDelta, source) => {
        if (this.checkUnsubscribeLink) {
          const parser = new DOMParser()
          const htmlDoc = parser.parseFromString(this.quill.root.innerHTML, 'text/html')
          const unsubscribeLink = htmlDoc.getElementsByClassName('temp-disable-link-action')

          if (unsubscribeLink.length === 0) {
            // eslint-disable-next-line max-depth
            if (!this.user.avoidUnsubscribeLink) {
              const link = `<p style="text-align: ${this.component.alignment};"><a
                  style="
                    color: ${this.component.urlColor};
                  "
                  class="temp-disable-link-action"
                  href="${this.component.redirectTo1}"
                  target="_blank"
                  contenteditable="false"
                >${this.component.text1}</a>
              </p>`
              this.quill.root.innerHTML += link
            }

            this.component.tinymce = htmlDoc.body.innerHTML
          }
        }
      })
      this.quill.on('text-change', (delta, oldDelta, source) => {
        const parser = new DOMParser()
        const htmlDoc = parser.parseFromString(this.quill.getSemanticHTML(), 'text/html')

        const emptyParagraphs = htmlDoc.querySelectorAll('p:empty')
        emptyParagraphs.forEach(p => {
          const brElement = document.createElement('br')
          p.replaceWith(brElement)
        })

        this.component.tinymce = htmlDoc.body.innerHTML
        this.$emit('textChanged')
      })

      if (this.nonEditable) {
        this.$el.querySelector('.ql-editor').classList.add('non-editable')
      }
    },
    redo () {
      this.quill.history.redo()
    },
    undo () {
      this.quill.history.undo()
    },
    resetEditorContent () {
      const content = this.component.tinymce

      // const clipboard = this.quill.getModule('clipboard')
      // const pastedDelta = clipboard.convert({ html: content })
      // const delta = new Delta().concat(pastedDelta)
      // this.quill.setContents(delta, Quill.sources.USER)

      this.quill.root.innerHTML = content
      setTimeout(() => {
        this.quill.history.clear()
      })
    },
    async insertUrl (url, text, formId = null) {
      const formIdDataAttr = formId ? `data-form-id="${formId}"` : ''
      const content = `<p style="
        text-align: center;
        font-size: ${this.component.fontSizeContent}px;
        color: #3F4254;
        font-family: Helvetica,Arial,sans-serif;
      ">
        <a
          style="
            text-align: center;
          "
          href="${url}"
          target="_blank"
          ${formIdDataAttr}
        >${text}</a>
      </p>`

      const clipboard = this.quill.getModule('clipboard')
      const pastedDelta = clipboard.convert({ html: content })
      const delta = new Delta()
                        .retain(this.range.index)
                        .delete(this.range.length)
                        .concat(pastedDelta)
      this.quill.updateContents(delta, Quill.sources.USER)
      this.component.tinymce = this.quill.root.innerHTML

      const linkElement = document.querySelector('a[href="' + url + '"]')

      if (linkElement) {
        // Add custom data attributes
        linkElement.setAttribute('data-form-id', formId)
      }
    },
    showMultimedia (currentRootDirectory) {
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
  },
}
