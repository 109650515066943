import moment from 'moment'

export class ScheduledReport {
  id = null
  userId = null
  channelTypeId = 0
  dateInit = ''
  dateEnd = ''
  name = ''
  events = []
  columns = []
  type = 'basic'
  frequency = ''
  dateTimeInitSend = ''
  dateTimeEndSend = ''
  sendToEmails = ''
  sendToManualEmails = ''
  campaigns = []
  updatedAt = ''
  createdAt = ''
  totalSent= 0
  nextSendAt = ''

  constructor (data) {
    if (data) {
      this.setData(data)
    }
  }

  setData (data) {
    this.id = data.id
    this.userId = data.user_id
    this.channelTypeId = data.channel_type_id
    this.dateInit = data.date_init
    this.dateEnd = data.date_end
    this.name = data.name
    this.events = data.events ? JSON.parse(data.events) : []
    this.columns = data.columns ? JSON.parse(data.columns) : []
    this.type = data.type
    this.frequency = data.frequency
    this.dateTimeInitSend = data.date_time_init_send ? moment(data.date_time_init_send).format('YYYY-MM-DDTHH:mm') : ''
    this.dateTimeEndSend = data.date_time_end_send ? moment(data.date_time_end_send).format('YYYY-MM-DDTHH:mm') : ''
    this.sendToEmails = data.send_to_emails ? JSON.parse(data.send_to_emails) : []
    this.sendToManualEmails = data.send_to_manual_emails ? JSON.parse(data.send_to_manual_emails) : []

    this.campaigns = data.campaigns ?? []
    this.updatedAt = data.updated_at
    this.createdAt = data.created_at
    this.totalSent = data.total_sent
    this.nextSendAt = data.next_send_at
  }

  static camelCaseToUnderscore (prop) {
    return prop.replace(/([A-Z])/g, '_$1').toLowerCase()
  }
}
